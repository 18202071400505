<script setup>
import Swiper from "swiper";
import { ref, onMounted, toRaw, unref } from "vue";

const swiperRef = ref();
const swiper = ref();
const realIndex = ref(0)

const swiperParams = {
    init: true,
    slidesPerView: 1,
    loop: true,
    speed: 500,
    spaceBetween: 0,
    paginationClickable: true,
    pagination: false,
    grabCursor: true,
    preventClicksPropagation: false,
    autoHeight: false,
    on: {
        slideChange: (swiper) => {
            realIndex.value = swiper.realIndex
        },
        init: (swiper) => {
            realIndex.value = swiper.realIndex
        }
    }
};

onMounted(() => {
    swiper.value = new Swiper(swiperRef.value, swiperParams);
});

function slideTo(index) {
    const swiperInstance = toRaw(unref(swiper));
    if (!swiperInstance) return;

    swiperInstance.slideTo(index)
}

function isActive(index) {
    return realIndex.value === index;
}
</script>

<template>
    <div class="section-case-slider__main">
        <div class="swiper" ref="swiperRef">
            <div class="swiper-wrapper">
                <slot name="default" />
            </div>
        </div>
    </div>
    <div class="section-case-slider__tabs">
        <slot name="rest" v-bind="{ slideTo, isActive }" />
    </div>
</template>

<style lang="scss"></style>
