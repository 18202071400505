import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

window.gsap = gsap;

export const init = () => {

    const sections = document.querySelectorAll('.section-stage--hero');

    sections.forEach(section => {
        const medias = section.querySelectorAll('.section-stage__images .media');
        const content = section.querySelector('.section-stage__content');

        const direction = 1;
        const percent = 30;
        const scale = 1.6;
        medias.forEach((media, index) => {

            gsap.fromTo(media,
                {
                    y: 70 + (1 * direction * percent * index) + '%',
                    scale: scale,
                },
                {
                    y: (-1 * direction * percent * index) + '%',
                    scale: scale,
                    ease: "none",
                    scrollTrigger: {
                        trigger: section,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true
                    }
            })
        })
    })
    // mediaBackgrounds.forEach(bg => {
    //     console.log(bg);

    //     gsap.to('.section-text-on-image__media', {
    //         y: "50%",
    //         ease: "none",
    //         scrollTrigger: {
    //             trigger: '.section-text-on-image',
    //             start: "top bottom",
    //             end: "bottom top",
    //             scrub: true
    //         }
    //     });

    // })
}

export default init;
